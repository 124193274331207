import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const Pooles = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const metaData = [{
    name: "keywords",
    content: slider_data[0].frontmatter.keywords,
  }];

  const image = getImage(slider_data[0].fields.thumbnail);

  const description = "My review of the Poole's Diner cookbook, after having made more than 40 of the recipes myself on video.";

  return (
    <Layout>
      <Seo title="Poole's Diner Cookbook Review" image={image} meta={metaData} description={description}/>
      <h1>Cookbook Review</h1>
      <h2>Poole's: Recipes and Stories from a Modern Diner</h2>
      <p>
        This is the cookbook that started my YouTube channel. To be entirely fair, 
        it was my husband's idea for us to record me cooking from all of the 
        lovely cookbooks that I own, and it was a self-serving idea. He wanted to
        EAT all the delicious food way more often than I would cook it without the 
        impetus of needing to record a video for the channel.
      </p>
      <p>
        Poole's Diner is a local restaurant for us, and while we have eaten there
        many times, it's one that has been hard for us to get to for dinner for a while
        now. We have kids, and it's not a very child-friendly restaurant, especially for 
        the younger ones. One time we took them, and my daughter looked very sad at the 
        "fancy" food we ordered for her, even though it was just roast chicken! To go 
        without them, for now, means a babysitter of some sort, and before the pandemic
        turned everyone's lives upside down, they did not take reservations. It was 
        chancy at best, if you weren't lined up at opening time on a weekend.
      </p>
      <p>
        So, we loved the food but couldn't eat there as often as we wanted. Enter:
        the cookbook. My ability to cook hasn't been the problem for quite some time,
        but finding the time and energy to do something different than the usual is.
        This cookbook made it possible for us to make our favorites, like the macaroni
        au gratin, and also to explore the menu items that we never got the chance to
        try in the restaurant.
      </p>
      <h3>The Pros</h3>
      <p>
        The best thing about this cookbook was that it showed us how to make some 
        of our favorites from the restaurant. The macaroni au gratin is in our 
        rotation now, and it's really much easier than I expected! There are 
        several basic recipes, for pickle brines, meat cures, compound butters, 
        condiments, and stocks. It also includes a lot of what the chef calls 
        "back pocket" recipes, things you can use in many different ways in 
        different recipes. These are things like vinaigrettes, charred onions, 
        roasted tomatoes, marinated vegetables, and crème fraîche. They add 
        something special to dishes in the book or you can use them for your 
        work-week sandwiches, salads, or dinners.
      </p>
      <p>
        The recipes also explain some techniques that can be easily adapted to 
        accomodate the ingredients you might have on hand -- varying the herbs,
        using slightly different cuts of meat, or similar vegetables. I make a 
        version of the charred summer squash pretty often throughout the summer, 
        when I have some on hand and want to do something a little different than 
        just roasted, grilled, or fried squash, but I rarely follow the recipe 
        exactly.
      </p>
      <p>
        While some of the recipes are pretty involved and take multiple "base" 
        recipes to complete or several steps with long wait times in between, 
        there are also a lot of really easy recipes! Poole's steak is something 
        anyone could master, and it doesn't even take a long time. I was also 
        happy that the pie crust recipe, something I am never happy to be making 
        myself, makes enough for four single-crust pies, and you are encouraged 
        to freeze whatever you don't use for another day.
      </p>
      <p>
        The book includes recipes for all of the kinds of things the restaurant 
        serves; snacks and appetizers, cocktails, salads, vegetables and sides, 
        entrées, and desserts. It even includes resources for places to order some 
        of the more difficult to find ingredients, so you don't have an excuse not
        to make something.
      </p>
      <h3>The Cons</h3>
      <p>
        The first thing I noticed, on the very first recipe we filmed from the 
        book, was that there are some places where the instructions don't quite 
        make sense, or don't translate well from what I assume are restaurant 
        portions down to home use. These are not generally hard to find or fix,
        <b>if</b> you're an experienced cook.
      </p>
      <p>
        The other big problem I had with this book is that some of the recipes 
        are a whole lot of work. Like...a lot. I assume that, in a restaurant 
        setting, using homemade stock for everything, mounting a sauce with 
        compound butters, using homemade mayonnaise and crème fraîche, makes 
        a lot more sense than at home. At least, in my home.
      </p>
      <p>
        I do make stocks quite often, and I keep them frozen in varying portions
        for future use. Compound butters also keep well in the freezer, but not
        everything does. So, for me, often making one recipe means actually 
        putting together three or four before I can even get started with the 
        instructions for the thing I want to make!
      </p>
      <p>
        A smaller problem with this book is that there are often ingredients 
        that I have some trouble finding. Luckily, in these days of the internet, 
        many things I can't find locally can be ordered online. And, the author 
        even includes places to do so in the back of the book. This is not an 
        insurmountable problem, but it does mean I have to plan ahead more than
        I often do.
      </p>
      <p>
        So, while I would allow that some of the recipes are both easy and quick,
        most are not. The techniques are sometimes a little <i>extra</i>. They make
        for a more refined end product, but can be time consuming and unnecessary for 
        my usual purposes. But if you want a restaurant quality meal at home, this 
        cookbook can help with that for sure.
      </p>
      <div className="all-videos">
        <h2>All Videos from This Cookbook</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/poole's diner/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default Pooles;
